import React, { useEffect, useState } from 'react';
import HomePage from './components/HomePage';

function App() {
  useEffect(() => {
    let preloader = document.getElementById("preloader")
    // callback function to call when event triggers
    const onPageLoad = () => {
      if(preloader){
        preloader.remove()
      }
    };
    
    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <>
      <div className="App">
        <HomePage />
      </div>
      <div id="preloader"></div>
    </>
  );
}

export default App;
