import React from 'react'
import ProgressBar from './ProgressBar'

function Skills() {
  const skills1 = [
    { skill: "HTML", value: 100 },
    { skill: "CSS", value: 100 },
    { skill: "JavaScript", value: 100 },
    { skill: "Solidity", value: 100 },
  ]
  const skills2 = [
    { skill: "Java", value: 100 },
    { skill: "Spring Boot", value: 100 },
    { skill: "C++", value: 100 },
  ]
  return (
    <>
      <section id="skills"  className="skills section-bg">
        <div  className="container">

          <div  className="section-title">
            <h2>Skills</h2>
            <p>I have grew my skillset by learning new launguages and framesworks in software development driven by curiousity. With the following list containing the skills which I used to build real world projects and I have my expertise in them.</p>
          </div>

          <div  className="row skills-content">

            <div  className="col-lg-6" data-aos="fade-up">
             { 
              skills1.map((ele,ind) => {
              return <ProgressBar key={ind} value={ele.value} skill={ele.skill} />;
             })
             }
            </div>
            <div  className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            { 
              skills2.map((ele,ind) => {
              return <ProgressBar key={ind} value={ele.value} skill={ele.skill} />;
             })
             }
            </div>

          </div>

        </div>
      </section>

    </>
  )
}

export default Skills
