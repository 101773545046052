import React, { useEffect } from 'react'


function Testimonals() {
  useEffect(() => {
    // Declare Swiper as a global variable
    const Swiper = window.Swiper;

    // Check if Swiper is available
    if (Swiper) {
      const swiper = new Swiper('.testimonials-slider', {
        speed: 600,
        loop: true,
        autoplay: {
          delay: 50000,
          disableOnInteraction: false
        },
        slidesPerView: 'auto',
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          1200: {
            slidesPerView: 1,
            spaceBetween: 20
          }
        }
      });

      // Clean up Swiper instance on component unmount
      return () => {
        swiper.destroy();
      };
    } else {
      console.error('Swiper is not defined. Please make sure Swiper is loaded globally.');
    }
  }, []); // Empty dependency array to run the effect only once on mount


  return (
    <>
      <section id="testimonials"  className="testimonials section-bg">
        <div  className="container">

          <div  className="section-title">
            <h2>Testimonials</h2>
            {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
          </div>

          <div  className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
            <div  className="swiper-wrapper">
              <div  className="swiper-slide">
                <div  className="testimonial-item" data-aos="fade-up">
                  <p>
                    <i  className="bx bxs-quote-alt-left quote-icon-left"></i>
                    What an incredible experience. Love this man. Fast and Efficient. He took the time to explain and show
                    us the things. He delivers exactly what you ask for on time. He is consistent both in communication
                    and the quality of work is amazing. Would love to work with this man again. Thank you.
                    <i  className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  {/* <img src="assets/img/testimonials/testimonials-1.jpg"  className="testimonial-img" alt="" /> */}
                  <h3>Saul Goodman</h3>
                  <h4>Ceo &amp; Founder</h4>
                </div>
              </div>
              <div  className="swiper-slide">
                <div  className="testimonial-item" data-aos="fade-up" data-aos-delay="100">
                  <p>
                    <i  className="bx bxs-quote-alt-left quote-icon-left"></i>

                    Not Only He Understand you and what you are expecting from the contract , he also let you know what
                    more things you can come out of your contract with his experience.. Highly Recommended to anyone, you
                    can Discuss any problem and he will be there for the solution and proper guidance .. !!

                    <i  className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  {/* <img src="assets/img/testimonials/testimonials-2.jpg"  className="testimonial-img" alt="" /> */}
                  <h3>Sara Wilsson</h3>
                  <h4>Designer</h4>
                </div>
              </div>
              <div  className="swiper-slide">
                <div  className="testimonial-item" data-aos="fade-up" data-aos-delay="200">
                  <p>
                    <i  className="bx bxs-quote-alt-left quote-icon-left"></i>
                    I gotta say this man was the nicest and most communicative person I could have gotten. additionally,
                    he is efficient. I came to him with a custom job and he created exactly what I wanted and what I was
                    looking for while answering all my questions.
                    <i  className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  {/* <img src="assets/img/testimonials/testimonials-3.jpg"  className="testimonial-img" alt="" /> */}
                  <h3>Jena Karlis</h3>
                  <h4>Store Owner</h4>
                </div>
              </div>
            </div>
            <div  className="swiper-pagination"></div>
          </div>

        </div>
      </section>

    </>
  )
}

export default Testimonals
