import React from 'react'

function Services() {
  return (
    <>
     <section id="services"  className="services">
          <div  className="container">
    
            <div  className="section-title">
              <h2>Services</h2>
              <p>I have grew my skillset by learning new launguages and framesworks in software development driven by curiousity. With the following list containing the skills which I used to build real world projects and I have my expertise in them.</p>
            </div>
    
            <div  className="row">
                
                <div  className="col-lg-4 col-md-6 icon-box " data-aos="fade-up">
                    <div style={{width:"100%"}}>
                        <div className="service-image block-chain">
                          <div  className="icon">
                            <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg"></svg>
                          </div>

                        </div>
                    <h4  className="title"><a href='/'>Blockchain Appilication Development</a></h4>
                    </div>
                </div>
                <div  className="col-lg-4 col-md-6 icon-box " data-aos="fade-up">
                    <div style={{width:"100%"}}>
                        <div className="service-image webdevelopment">
                          <div  className="icon">
                            <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg"></svg>
                          </div>

                        </div>
                        <h4  className="title"><a href='/'>Web Appilication Development</a></h4>
                    </div>
                </div>
                <div  className="col-lg-4 col-md-6 icon-box " data-aos="fade-up">
                    <div style={{width:"100%"}}>
                        <div className="service-image  springboot">
                          <div  className="icon">
                            <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg"></svg>
                          </div>
                        </div>
                    <h4  className="title"><a href='/'>Spring Appilication Development</a></h4>
                    </div>
                </div>
                
            </div>
          </div>
        </section> 
    </>
  )
}

export default Services
