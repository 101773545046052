import React from 'react'

function Footer() {
  return (
    <>
      <footer id="footer">
        <div  className="profile">
          <h1  className="text-light mb-3">Prasad Padala</h1>
          <div  className="social-links mb-3 text-center">
            <a href="https://www.linkedin.com/in/prasad-padala-3a61a4244/" target="_blank" className="linkedin"><i  className="bx bxl-linkedin"></i></a>
            <a href="https://discord.com/users/1041355272511307849" target="_blank"  className="discord"><i  className="bx bxl-discord"></i></a>
            <a href="https://twitter.com/Prasad_5172" target="_blank"  className="twitter"><i  className="bx bxl-twitter"></i></a>
            <a href="https://www.instagram.com/durga_5172/" target="_blank"  className="instagram"><i  className="bx bxl-instagram"></i></a>
          </div>
        </div>
        <div  className="container">
          <div  className="copyright">
            &copy; Copyright <strong><span>Prasad</span></strong>.
            All Rights Reserved
          </div>
          <div  className="credits">
          </div>
        </div>
      </footer>

    </>
  )
}

export default Footer
