import React from 'react'

function About() {
  return (
    <>
      <section id="about"  className="about">
        <div  className="container">

          <div  className="section-title">
            <h2>About</h2>
            <p>Hi , I am PRASAD . Blockchain Developer|Tech Entusiast. I love solving real world problems through softwares. I'm a Freshman pursuing Computer Science Engineering at IIIT , Nuzvid. I am a Self-Taught Programmer,started my journey in programming when I was 16!</p>
          </div>

          <div  className="row">
            <div  className="col-lg-4" data-aos="fade-right">
              <img src="insta/insta2square.JPG"  className="img-fluid" alt="" />
            </div>
            <div  className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
              <h3>Blockchain Developer;Full Stack Web Developer.</h3>
              {/* <p  className="fst-italic">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </p> */}
              <div  className="row">
                <div  className="col-lg-6">
                  <ul>
                    <li><i  className="bi bi-chevron-right"></i> <strong>Degree:</strong> <span>Undegrad CS Engineering</span></li>
                    <li><i  className="bi bi-chevron-right"></i> <strong>City:</strong> <span>Visakapatnam,AP,INDIA</span></li>
                  </ul>
                </div>
                <div  className="col-lg-6">
                  <ul>
                    <li><i  className="bi bi-chevron-right"></i> <strong>Email:</strong> <span>webchains3@gmail.com</span></li>
                    <li><i  className="bi bi-chevron-right"></i> <strong>Freelance:</strong> <span>Available</span></li>
                  </ul>
                </div>
              </div>
              <p>
              With a robust background in React application development and Spring Boot, I've effectively channeled my passion for technology into impactful projects.
               My journey commenced with React development, and since then, I've continuously expanded my skill set, delving into the realm of Spring Boot to build scalable and efficient backend solutions. 
               Beyond traditional web development, I've demonstrated my versatility by creating a music app—a testament to my commitment to crafting immersive digital experiences. 
               This project provides users to explore and enjoy music, showcasing my dedication to user-centric design. 
               I am always ready to have new experiences, meet new people and learn new things,I like pushing myself and taking up new challenges.
              </p>
            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default About
