import React, { useEffect } from 'react';
import GLightbox from 'glightbox';

function Portfolio() {
  useEffect(() => {
    // Initialize GLightbox
    const lightbox = GLightbox({
      selector: '.portfolio-lightbox',
    });

    // Cleanup: Remove the lightbox instance when the component unmounts
    return () => {
      lightbox.destroy();
    };
  }, []);


  // Sample data for portfolio items
const portfolioItems = [
  {
    imageSrc: "/portfolioImages/chainguardians.png",
    filter: "filter-app",
    title: "App 1",
    detailsLink: "https://chainguardians.io/governance",
  },
  {
    imageSrc: "/portfolioImages/chainboost.png",
    filter: "filter-app",
    title: "App 1",
    detailsLink: "https://chainboost.io/",
  },
  {
    imageSrc: "/portfolioImages/izzyai.png",
    filter: "filter-app",
    title: "App 1",
    detailsLink: "https://izzyai.com",
  },
  {
    imageSrc: "/portfolioImages/vtm.png",
    filter: "filter-app",
    title: "App 1",
    detailsLink: "https://vtm.ai",
  },
  {
    imageSrc: "/portfolioImages/boostmobiles.png",
    filter: "filter-app",
    title: "App 1",
    detailsLink: "http://boostmobiledc.com/",
  },
  {
    imageSrc: "/portfolioImages/spotify.png",
    filter: "filter-app",
    title: "App 1",
    detailsLink: "https://ill-shawl-lamb.cyclic.cloud/",
  },
  {
    imageSrc: "/portfolioImages/chainguardians2.png",
    filter: "filter-app",
    title: "App 1",
    detailsLink: "https://chainguardians.io/governance",
  },
  {
    imageSrc: "/portfolioImages/chainboost2.png",
    filter: "filter-app",
    title: "App 1",
    detailsLink: "https://chainboost.io/",
  }
  
  // Add more portfolio items as needed
];

  return (
    <>
      <section id="portfolio" className="portfolio section-bg">
        <div className="container">
          <div className="section-title">
            <h2>Portfolio</h2>
            <p>
              I believe in learning by doing. I build projects that are
              challenging so that I can learn more about the Technology
              effectively and efficiently.
            </p>
          </div>

          {/* <div className="row" data-aos="fade-up">
            <div className="col-lg-12 d-flex justify-content-center">
              <ul id="portfolio-flters">
                <li data-filter="*" className="filter-active">
                  All
                </li>
                <li data-filter=".filter-app">App</li>
                <li data-filter=".filter-web">Web</li>
                <li data-filter=".filter-card">Web3</li>
              </ul>
            </div>
          </div> */}

          <div
            className="row portfolio-container"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            {/* Map through your portfolio items and create the elements */}
            {portfolioItems.map((item, index) => (
              <div
                key={index}
                className={`col-lg-4 col-md-6 portfolio-item ${item.filter}`}
              >
                <div className="portfolio-wrap">
                  <img
                    src={item.imageSrc}
                    className="img-fluid"
                    alt={item.title}
                  />
                  <div className="portfolio-links">
                    <a
                      href={item.imageSrc}
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox"
                      title={item.title}
                    >
                      <i className="bx bx-plus"></i>
                    </a>
                    <a href={item.detailsLink} target="_blank" title="More Details">
                      <i className="bx bx-link"></i>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}



export default Portfolio;
