import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import About from './About'
import Facts from './Facts'
import Skills from './Skills'
import Portfolio from './Portfolio'
import Services from './Services'
import Testimonals from './Testimonals'
import Contact from './Contact'
import { TypeAnimation } from 'react-type-animation';


function HomePage() {
  const [isDarkModeActive,setIsDarkModeActive] = useState(false)
  const [isSideBarVisible, setIsSideBarVisible] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY; // => scroll position
    var scrollerIcon = document.getElementById("scroll")
    if (scrollPosition > 720) {
      scrollerIcon.classList.add("active")
    } else {
      scrollerIcon.classList.remove("active")
    }
  };


  
  const handlePage = (id) => {
    
    let section = document.querySelector(id);
    window.scrollTo({ top: section.offsetTop, behavior: "smooth" })
    
}


  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <>
      <Navbar isDarkModeActive={isDarkModeActive} setIsDarkModeActive={setIsDarkModeActive} handlePage={handlePage} isSideBarVisible={isSideBarVisible} setIsSideBarVisible={setIsSideBarVisible}/>
  
      <section id="hero"  className="d-flex flex-column justify-content-center align-items-end  padding-right-40" >
        <div  className="hero-container" data-aos="fade-in">
          <h1>Hi,I'm Prasad</h1>
          <p>
            I'm{' '}
            <TypeAnimation
              sequence={[
                'a Blockchain Developer',1500,
                'a Full Stack Web Developer ',1000,
                'a Spring Boot Developer',1000,
                'a Tech Enthusiast',1000
              ]}
              wrapper="span"
              speed={50}
              style={{ display: 'inline-block' }}
              repeat={Infinity}
            />
          </p>
        </div>
      </section>

      <main id="main" className={`${isDarkModeActive ? "dark-theme":""}`}>
        <About />
        <Facts />
        <Skills />
        {/* <Resume /> */}
        <Portfolio />
        <Services />
        <Testimonals />
        <Contact />





        <Footer />
        <div onClick={() => window.scrollTo(0, 0)}  className="back-to-top d-flex align-items-center justify-content-center " id='scroll'><i  className="bi bi-arrow-up-short "></i></div>
      </main>




    </>
  )
}



export default HomePage
