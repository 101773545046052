import React,{useEffect} from 'react'
import PureCounter from "@srexi/purecounterjs";

function Facts() {
  useEffect(() => {
    new PureCounter({
      // Setting that can't' be overriden on pre-element
      selector: ".purecounter1", // HTML query selector for spesific element
  
      // Settings that can be overridden on per-element basis, by `data-purecounter-*` attributes:
      start: 0, // Starting number [uint]
      end: 6, // End number [uint]
      duration: 2, // The time in seconds for the animation to complete [seconds]
      delay: 10, // The delay between each iteration (the default of 10 will produce 100 fps) [miliseconds]
      once: true, // Counting at once or recount when the element in view [boolean]
      pulse: false, // Repeat count for certain time [boolean:false|seconds]
      decimals: 0, // How many decimal places to show. [uint]
      legacy: true, // If this is true it will use the scroll event listener on browsers
      filesizing: false, // This will enable/disable File Size format [boolean]
      currency: false, // This will enable/disable Currency format. Use it for set the symbol too [boolean|char|string]
      formater: "us-US", // Number toLocaleString locale/formater, by default is "en-US" [string|boolean:false]
      separator: false, // This will enable/disable comma separator for thousands. Use it for set the symbol too [boolean|char|string]
  });
    new PureCounter({
      // Setting that can't' be overriden on pre-element
      selector: ".purecounter2", // HTML query selector for spesific element
  
      // Settings that can be overridden on per-element basis, by `data-purecounter-*` attributes:
      start: 0, // Starting number [uint]
      end: 10, // End number [uint]
      duration: 2, // The time in seconds for the animation to complete [seconds]
      delay: 10, // The delay between each iteration (the default of 10 will produce 100 fps) [miliseconds]
      once: true, // Counting at once or recount when the element in view [boolean]
      pulse: false, // Repeat count for certain time [boolean:false|seconds]
      decimals: 0, // How many decimal places to show. [uint]
      legacy: true, // If this is true it will use the scroll event listener on browsers
      filesizing: false, // This will enable/disable File Size format [boolean]
      currency: false, // This will enable/disable Currency format. Use it for set the symbol too [boolean|char|string]
      formater: "us-US", // Number toLocaleString locale/formater, by default is "en-US" [string|boolean:false]
      separator: false, // This will enable/disable comma separator for thousands. Use it for set the symbol too [boolean|char|string]
  });
    new PureCounter({
      // Setting that can't' be overriden on pre-element
      selector: ".purecounter3", // HTML query selector for spesific element
  
      // Settings that can be overridden on per-element basis, by `data-purecounter-*` attributes:
      start: 0, // Starting number [uint]
      end: 200, // End number [uint]
      duration: 2, // The time in seconds for the animation to complete [seconds]
      delay: 10, // The delay between each iteration (the default of 10 will produce 100 fps) [miliseconds]
      once: true, // Counting at once or recount when the element in view [boolean]
      pulse: false, // Repeat count for certain time [boolean:false|seconds]
      decimals: 0, // How many decimal places to show. [uint]
      legacy: true, // If this is true it will use the scroll event listener on browsers
      filesizing: false, // This will enable/disable File Size format [boolean]
      currency: false, // This will enable/disable Currency format. Use it for set the symbol too [boolean|char|string]
      formater: "us-US", // Number toLocaleString locale/formater, by default is "en-US" [string|boolean:false]
      separator: false, // This will enable/disable comma separator for thousands. Use it for set the symbol too [boolean|char|string]
  });
  },[])
  
  
  return (
    <>
      <section id="facts"  className="facts">
        <div  className="container">

          <div  className="section-title">
            <h2>Facts</h2>
            <p>I love adding value to the world through my work , I do it through freelancing. I provide high value to the clients through my high quality work.</p>
          </div>

          <div  className="row no-gutters">


            <div  className="col-lg-4 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up">
              <div  className="count-box">
                <i  className="bi bi-emoji-smile"></i>
                <p className='counter-p'><span  className="purecounter1">0</span>+</p>
                <p><strong>Happy Clients</strong></p>
                </div>
            </div>


            <div  className="col-lg-4 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="100">
              <div  className="count-box">
                <i  className="bi bi-journal-richtext"></i>
                <p className='counter-p' ><span  className="purecounter2">0</span>+</p>
                <p><strong>Projects</strong></p>
              </div>
            </div>

            <div  className="col-lg-4 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="200">
              <div  className="count-box">
                <i  className="bi bi-headset"></i>
                <p className='counter-p'><span  className="purecounter3">0</span>+</p>
                <p><strong>Hours Of Support</strong></p>
              </div>
            </div>


          </div>

        </div>
      </section>
    </>
  )
}

export default Facts
