import React, { useState, useEffect } from 'react'
import ThemeSwitch from './ThemeSwitch/ThemeSwitch';

function Navbar(props) {
  const {isSideBarVisible, setIsSideBarVisible} = props;
  
  let screenWidth = window.innerWidth;
  const isMiddelScreen = screenWidth < 600;

  const handleClick = () => {
    setIsSideBarVisible(!isSideBarVisible)
    if (isSideBarVisible) {
      document.body.classList.remove("mobile-nav-active")
    } else {
      document.body.classList.add("mobile-nav-active")
    }
  }
  let header = document.getElementById('header')
  let theme = document.getElementById('theme-switch')
  let hero =  document.getElementById('hero')
  
  window.onclick = function (e) {
    if(isSideBarVisible){
      if( e.target.id !='preloader'){
        if (!header.contains(e.target) && !theme.contains(e.target) && e.target.id !='menu-bar') {
            // console.log("closing ")
            handleClick();
        }
      }
    }
}


  useEffect(() => {
    const navbarlinks = document.querySelectorAll('#navbar .scrollto');
    // console.log(navbarlinks)
    const handleScroll = () => {
      let screenHeight = window.innerHeight *0.96;
      let position = window.scrollY + 200;
      // console.log(position)
      console.log(screenWidth)
      if(position < screenHeight && !isMiddelScreen){
        document.getElementById("header").style.background = "none";
      }else{
        document.getElementById("header").style.background = "#040b14";
      }

      

      navbarlinks.forEach(navbarlink => {
        // console.log(navbarlink)
        let section = document.querySelector(navbarlink.getAttribute("id"));
        if (!section) return;
        if (position >= section.offsetTop && position <= section.offsetTop + section.offsetHeight) {
          navbarlink.classList.add('active');
        } else {
          navbarlink.classList.remove('active');
        }
      });
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
      <ThemeSwitch {...props}/>
      <i  className={`bi ${isSideBarVisible ? "bi-x" : "bi-list"} mobile-nav-toggle d-xl-none`} id='menu-bar' onClick={handleClick} ></i>

      <header id="header">
        <div  className="d-flex flex-column">

          <div  className="profile">
            <img src="insta/insta2square.JPG" alt=""  className="img-fluid rounded-circle" />
            <h1  className="text-light"><a href="index.html">Prasad</a></h1>
            <div  className="social-links mt-3 text-center">
              <a href="https://www.linkedin.com/in/prasad-padala-3a61a4244/" target="_blank"  className="linkedin"><i  className="bx bxl-linkedin"></i></a>
              <a href="https://discord.com/users/1041355272511307849" target="_blank"  className="discord"><i  className="bx bxl-discord"></i></a>
              <a href="https://twitter.com/Prasad_5172" target="_blank"  className="twitter"><i  className="bx bxl-twitter"></i></a>
              <a href="https://www.instagram.com/durga_5172/" target="_blank"  className="instagram"><i  className="bx bxl-instagram"></i></a>
              <a href="https://github.com/Prasad5172" target="_blank"  className="instagram"><i  className="bx bxl-github"></i></a>
            </div>
          </div>

          <nav id="navbar"  className="nav-menu navbar">
            <ul>
              <li><div id="#hero" className="nav-link scrollto active" onClick={() => {
                props.handlePage("#hero");
                handleClick()
                }} style={{cursor:"pointer"}}><i className="bx bx-home"></i> <span>Home</span></div></li>
              <li><div id="#about"className="nav-link scrollto " onClick={() => {
                props.handlePage("#about");
                handleClick()
                }} style={{cursor:"pointer"}} ><i className="bx bx-user"></i> <span>About</span></div></li>
              {/* <li><div id="#resume" className="nav-link scrollto  " onClick={() => {
                props.handlePage("#resume");
                handleClick()
                }}><i className="bx bx-file-blank"></i> <span>Resume</span></div></li> */}
              <li><div id="#portfolio" className="nav-link scrollto  " onClick={() => {
                props.handlePage("#portfolio");
                handleClick()
                }} style={{cursor:"pointer"}}><i className="bx bx-book-content"></i> <span>Portfolio</span></div></li>
              <li><div id="#services" className="nav-link scrollto " onClick={() => {
                props.handlePage("#services");
                handleClick()
                }} style={{cursor:"pointer"}}><i className="bx bx-server"></i> <span>Services</span></div></li>
              <li><div id="#contact" className="nav-link scrollto " onClick={() => {
                props.handlePage("#contact");
                handleClick()
                }} style={{cursor:"pointer"}}><i className="bx bx-envelope"></i> <span>Contact</span></div></li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  )
}

export default Navbar
